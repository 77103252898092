<template>
    <div class="curation_list_wrap">
        <ul class="curation_list flex_column">
            <li
                class="curation_item dp_flex cursor"
                v-for="item in curationtimelist"
                @click="$emit('click-curationitem', item)"
                :key="item.curationId"
                :class="{ active: $store.getters['player/getCreatorCurationId'] === item.curationId && isMusicPlay }"
            >
                <div :class="'color_line' + ' ' + item.colorcss"></div>
                <div class="curation_contents">
                    <div class="flex_space_between curation_contents_wrap">
                        <h2 class="curation_contents_name" v-if="item.title">
                            {{ item.title }}
                        </h2>
                        <h2 class="curation_contents_name" v-else>
                            {{ item.startAmPm }} {{ item.startTimeTitle }} ~ {{ item.endAmPm }} {{ item.endtTimeTitle }}
                        </h2>
                        <div
                            class="curation_contents_eq align_center"
                            v-if="$store.getters['player/getCreatorCurationId'] === item.curationId && isMusicPlay"
                        >
                            <equalizer-comp />
                        </div>
                    </div>
                    <h4 class="day_list" v-if="item.day">{{ item.day }}</h4>
                    <ul class="tag_list dp_flex">
                        <li class="tag_item">{{ item.target }}</li>
                        <li class="tag_item">{{ item.domesticRate }}</li>
                        <li class="tag_item">{{ item.genre }}</li>
                        <li class="tag_item">{{ item.period }}</li>
                    </ul>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'CurationTimeList',
  emits: ['click-curationitem'],
  props: {
    curationtimelist: {
      type: Array,
      default: () => {
        return [];
      },
      required: false
    }
  },
  components: {
    'equalizer-comp': () => import(`@/components/player/Equalizer.vue`)
  },
  data () {
    return {};
  },

  mounted () {},

  methods: {},
  computed: {
    isMusicPlay () {
      return this.$store.getters.gettersIsPlay;
    }
  }
});
</script>
<style scoped src="@/assets/css/curation/curationtimelist.css"></style>
